import { createUploadedFileFromUrl } from "@/utils";
import { UserStatus } from "@/utils/api-client";
import { Phone } from "@/utils/api-client/models/common/Phone";
import { IndividualIdentification } from "@/utils/api-client/models/identification/IndividualIdentification";
import { UploadedFile } from "@/utils/api-client/models/upload/UploadedFile";
import { createIdentificationManager } from "@/utils/di";
import moment from "moment/moment";

type Identification = {
  readonly firstName?: string;
  readonly middleName?: string;
  readonly lastName?: string;
  readonly birthday?: Date;
  readonly birthplace?: string;
  readonly inn?: string;
  readonly registerAddress?: string;
  readonly passportNumber?: string;
  readonly passportIssuedDate?: Date;
  readonly passportDepartmentCode?: string;
  readonly passportIssuedBy?: string;
  readonly passportFirstScan: Array<UploadedFile>;
  readonly passportSecondScan: Array<UploadedFile>;
  readonly ogrnip?: string;
  readonly companyName?: string;
  readonly companySiteUrl?: string;
  readonly companyInn?: string;
  readonly companyKpp?: string;
  readonly companyOgrnip?: string;
  readonly companyAddress?: string;
  readonly phone?: string;
  readonly userContractTerms?: boolean;
  readonly personalDataPolicy?: boolean;
};

export class IdentificationManager {
  async findOne(): Promise<Identification> {
    const manager = await createIdentificationManager();
    const data = await manager.findOne();
    return {
      firstName: data?.person?.firstName,
      middleName: data?.person?.middleName,
      lastName: data?.person?.lastName,
      birthday: data?.person && new Date(data.person.birthday),
      birthplace: data?.person?.birthplace,
      inn: data?.inn,
      registerAddress: data?.registerAddress,
      passportNumber: data?.passport && data.passport.series + " " + data.passport.number,
      passportIssuedDate: data?.passport && new Date(data.passport.issuedDate),
      passportDepartmentCode: data?.passport?.departmentCode,
      passportIssuedBy: data?.passport?.issuedBy,
      passportFirstScan: data?.passport?.scanCopies[0] ? [createUploadedFileFromUrl(data?.passport?.scanCopies[0])] : [],
      passportSecondScan: data?.passport?.scanCopies[1] ? [createUploadedFileFromUrl(data?.passport?.scanCopies[1])] : [],
      ogrnip: data?.ogrnip,
      companyName: data?.company?.name,
      companySiteUrl: data?.company?.siteUrl,
      companyInn: data?.company?.inn,
      companyKpp: data?.company?.kpp,
      companyOgrnip: data?.company?.ogrnip,
      companyAddress: data?.company?.address,
      phone: data?.phone && "+" + data.phone.countryCode + data.phone.number
    };
  }

  async create(userStatus: UserStatus, identification: Identification): Promise<void> {
    const { firstName, middleName, lastName, birthplace } = identification;

    const birthday = moment(identification.birthday);

    if (!firstName || !lastName || !birthday || !birthplace) {
      throw new Error("Invalid data");
    }

    const { inn, registerAddress } = identification;
    if (!inn || !registerAddress) {
      throw new Error("Invalid data");
    }

    const { passportNumber, passportDepartmentCode, passportIssuedBy, passportFirstScan, passportSecondScan } = identification;
    const passportIssuedDate = moment(identification.passportIssuedDate);

    if (!passportNumber || !passportIssuedDate || !passportDepartmentCode || !passportIssuedBy) {
      throw new Error("Invalid data");
    }
    const [passportSeries, passNumber] = passportNumber.split(" ");

    const { phone } = identification;
    if (!phone) {
      throw new Error("Invalid data");
    }
    const phoneCountryCode = "7";
    const phoneNumber = phone.replace(/\D/g, "").substr(1);

    const { userContractTerms, personalDataPolicy } = identification;
    if (!userContractTerms || !personalDataPolicy) {
      throw new Error("Invalid data");
    }

    const individualIdentification: IndividualIdentification = {
      person: { firstName, middleName, lastName, birthday: birthday.toISOString(), birthplace },
      inn,
      registerAddress,
      passport: {
        series: passportSeries,
        number: passNumber,
        issuedDate: passportIssuedDate.toISOString(),
        departmentCode: passportDepartmentCode,
        issuedBy: passportIssuedBy,
        scanCopies: [passportFirstScan[0].url, passportSecondScan[0].url]
      },
      phone: new Phone(phoneCountryCode, phoneNumber),
      agreements: { userContractTerms, personalDataPolicy }
    };

    const manager = await createIdentificationManager();
    if (userStatus === UserStatus.LEGAL_ENTITY) {
      const { companyName, companySiteUrl, companyInn, companyKpp, companyOgrnip, companyAddress  } = identification;
      if (!companyName || !companyInn || !companyKpp || !companyOgrnip || !companyAddress) {
        throw new Error("Invalid data");
      }

      await manager.createLegalEntityIdentification({
        ...individualIdentification,
        company: {
          name: companyName,
          siteUrl: companySiteUrl,
          inn: companyInn,
          kpp: companyKpp,
          ogrnip: companyOgrnip,
          address: companyAddress
        }
      });
    } else if (userStatus === UserStatus.ENTREPRENEUR) {
      const { ogrnip  } = identification;
      if (!ogrnip) {
        throw new Error("Invalid data");
      }

      await manager.createEntrepreneurIdentification({ ...individualIdentification, ogrnip });
    } else {
      await manager.createIndividualIdentification(individualIdentification);
    }
  }
}
